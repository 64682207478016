// css for parent value components
@use 'config' as *;

// custom error message

.custom-error-message {
  font-size: 12px;
  margin-top: -1em;
  padding-bottom: 1em;
}

.mat-mdc-form-field-error {
  grid-row-start: 1;
  grid-row-end: auto;
  grid-column-start: 3;
  grid-column-end: end;
}

.ck-content code {
  font-family: monospace !important;
}

.ck-content pre {
  background-color: inherit !important;
}

code,
pre {
  font-family: monospace !important;
  background-color: hsla(0, 0%, 78%, 0.3);
  padding: 0.15em;
  border-radius: 2px;
}
